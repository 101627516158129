import React from "react"  
import "./layout.css" 
import prop2 from '../images/Logo-forweb.png' 
import { Row, Col } from "reactstrap"
import '../themes/css/bootstrap.min.css'
import '../themes/css/now-ui-kit.min.css'
type LayoutProps = {
  header: any
  children: any
}

const Layout = ( props: LayoutProps ) => {
   
  return (
    <>
      {props.header}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{props.children}</main>
        <Row className="justify-content-center">  
         <Col xs={4} md="3">
          <img src={prop2} alt=""/> 
         </Col> 

       </Row>

      </div>
      <footer style={{background: '#1c2a31', textAlign: "center", color: '#fff', fontSize: 12, padding: '10px 0', position: 'fixed', bottom: 0, width: '100%'}}>
          © {new Date().getFullYear()} National Marketing Campaign, Powerd by <a href="https://geonet.me" target="_blank" rel="noopener noreferrer">Geonet.me</a> for Australian Property & Finance Group
           
        </footer>
    </>
  )
}
 
export default Layout

/*

*/

import React, { useState, useEffect } from "react"
import Layout from "./components/layout"
import logo from './images/logo-white.png'
import { Row, Col, Card, CardBody, CardFooter, FormGroup, Label, Input } from "reactstrap"
import Nouislider from 'nouislider-react';
import { Helmet } from 'react-helmet'
import "nouislider/distribute/nouislider.css";
 
const pertanyaanAwal = 'Are you Married or DeFacto'

function formatRupiah(angka: any, prefix: any) {
  var number_string = angka.replace(/[^,\d]/g, '').toString(),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    let separator = sisa ? ',' : '';
    rupiah += separator + ribuan.join(',');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? '$ ' + rupiah : '');
}

interface mainProps { // extends RouteComponentProps
  uid: string,
  firebaseApp: firebase.app.App
}

const App = (props: mainProps) => {
  const defdataQ: any = {}
  const [dataQ, setDataQ] = useState(defdataQ)
  const [displayState, setDisplayState] = useState("pertanyaan0")
  const [complateQuestion, setComplateQuestion] = useState(0)
  const [valData, setValData] = useState(undefined)
  const [slidePercent, setSlidePercent] = useState(0)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const urlParams = new URLSearchParams(window.location.search);
  const audience = urlParams.get('audience')
  const ad = urlParams.get('ad')
  console.log("history", audience, ad)
  let display = <div />
  const vv = valData ? valData : pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 0

  useEffect(() => {
    const x = valData ? valData : pertanyaan[displayState] && pertanyaan[displayState].input && pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 0
    const y = pertanyaan[displayState] && pertanyaan[displayState].input && pertanyaan[displayState].input.range && pertanyaan[displayState].input.range[1] ? pertanyaan[displayState].input.range[1] : 1000000000
    const pr = x / y * 100
    setSlidePercent(pr)
  }, [displayState, valData])

  if (displayState === 'done') {

    let score = 0
    display = <div>
      <h3>
        Thank you, {dataQ['01 - Name']} <br />
      We appreciate you taking the time to fill in our survey.  One of our Financial Advisors will be in touch with you very soon.
      If you would like more information, visit us at <a href="https://apfg.com.au/">https://apfg.com.au/</a>.
      <br />
We look forward to speaking with you.
    </h3>
    </div>


    if ((dataQ['08 - Income Value'] >= 90000 && dataQ['05 - Marital Status'] === "NO") || (dataQ['08 - Income Value'] >= 120000 && dataQ['05 - Marital Status'] === "YES")) {
      score += 1
      console.log("Masuk Income")
    }

    if (dataQ['09 - Saving Value'] >= 60000 || dataQ['10 - Superannuation Value'] >= 250000) {
      score += 1
      console.log("Masuk Saving")
    }
    if (score > 1) {
      display = <div>
        <h3>
          Thank you, {dataQ['01 - Name']} <br />
      Congratulations!  You have qualified for instant scheduling with one of our Financial Advisors.  <br />
      Please choose your appointment time below to book your free consultation.   Our team will be in touch very soon to confirm your appointment.<br />
          <a className="btn btn-info" href="https://calendly.com/apfg/strategy-session">Book Now</a>
          <br />
      If you need more information or assistance, you can reach us at <a href="mailto:info@apfg.com.au">info@apfg.com.au</a> or <a href="tel:610280051395">(02) 8005 1395</a>, or on our website <a href="https://apfg.com.au/">https://apfg.com.au/</a>.
      <br />
      We look forward to speaking with you.
    </h3>
      </div>
    }

  } else {

    const tampil = formatRupiah(vv.toString(), 0)

    const pertanyaanExtra = []
    const isiRadio = []
    if (pertanyaan[displayState].centang && pertanyaan[displayState].input.type === 'radio') {
      console.log("Masuk sini")
      for (const x of pertanyaan[displayState].centang) {
        isiRadio.push(
          <FormGroup key={x} check className="form-check-radio">
            <Label check>
              <Input
                defaultValue="option1"
                name="exampleRadios"
                type="radio"
                onClick={() => {
                  const data = dataQ
                  data[pertanyaan[displayState].dataType] = x
                  console.log(data)
                  setDataQ(data)
                  setValData(x)
                }}
              ></Input>
              <span className="form-check-sign"></span>
              {x}
            </Label>
          </FormGroup>
        )
      }
    }


    if (displayState === 'pertanyaan5') {
      for (const x of pertanyaan.pertanyaan5.centang) {
        pertanyaanExtra.push(
          <div key={x} style={{ textAlign: 'left' }} className="form-check">
            <label className="form-check-label">
              <input className="form-check-input" type="checkbox" onChange={(evt) => {
                if (evt.target.checked) {
                  const data = dataQ
                  if (!data[`Superannuation In`]) {
                    data[`Superannuation In`] = []
                  }
                  data[`Superannuation In`].push(x)
                  setDataQ(data)
                } else {
                  const data = dataQ
                  const y = data[`Superannuation In`].indexOf(x)
                  data[`Superannuation In`].splice(y, 1)
                  setDataQ(data)
                }
              }} />
              <span className="form-check-sign"></span>
              {x}
            </label>
          </div>
        )
      }
    }

    let testi = <span />
    if (displayState === 'pertanyaan10') {
      testi = <div style={{ marginTop: 30 }}> </div>
    }
    display = <div>
      <Row className="justify-content-center">
        <Col xs='12'>

          <h3> {slidePercent > 20 && dataQ['05 - Marital Status'] === 'YES' && pertanyaan[displayState].pertanyaan.length > 1 ? pertanyaan[displayState].pertanyaan[1] : pertanyaan[displayState].pertanyaan[0]} </h3>
          <p style={{ display: pertanyaan[displayState].sub ? '' : 'none' }}>{pertanyaan[displayState].sub} </p>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginBottom: 30, display: pertanyaan[displayState].input.type === 'slide' ? '' : 'none' }}>
        <Col xs='12'>
          <h3 style={{ textAlign: 'center' }}>{tampil}</h3>
          <Nouislider
            range={{ min: pertanyaan[displayState].input.range && pertanyaan[displayState].input.range[0] ? pertanyaan[displayState].input.range[0] : 0, max: pertanyaan[displayState].input.range && pertanyaan[displayState].input.range[1] ? pertanyaan[displayState].input.range[1] : 1 }}
            start={[valData ? valData : pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 1]}
            step={1000}
            onSlide={(evt: any) => {
              console.log("VALUE SLIDER", evt[0])
              const ss = evt[0] / 1
              setValData(ss as any)
            }}
            onChange={(evt: any[]) => {
              console.log("VALUE SLIDER", evt[0])
              const ss = evt[0] / 1
              setValData(ss as any)
            }}
          />
        </Col>

        <Col xs={12} style={{ marginTop: 20 }}>
          <p style={{ display: pertanyaan[displayState].subQuestion ? '' : 'none' }}>{pertanyaan[displayState].subQuestion} </p>

          {pertanyaanExtra}


        </Col>
      </Row>
      <Row className="justify-content-center" id="Radio" style={{ marginBottom: 30, textAlign: 'left', display: pertanyaan[displayState].input.type === 'radio' ? '' : 'none' }}>
        <Col xs={12} style={{ marginTop: 20 }}> {isiRadio}</Col>
      </Row>
      <Row className="justify-content-center" style={{ marginBottom: 50, display: pertanyaan[displayState].input.type === 'text' ? '' : 'none' }}>
        <Col md={pertanyaan[displayState].input.width ? pertanyaan[displayState].input.width : 10}>
          <input className="form-control" style={{ fontSize: 18, textAlign: 'center' }} type='text' placeholder={pertanyaan[displayState].input.placeholder ? pertanyaan[displayState].input.placeholder : ''}
            value={valData ? valData : ''}
            onChange={(evt: any) => {
              const val = evt.target.value
              setValData(val)
            }}
            onBlur={(evt: any) => {
              const val = evt.target.value
              setValData(val)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ marginTop: 0 }}>
          <p style={{ display: pertanyaan[displayState].sub2 ? '' : 'none' }}>{pertanyaan[displayState].sub2} </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ marginTop: 0 }}>
          <p style={{ display: errorMessage ? '' : 'none', color: '#ff0000', fontWeight: 800 }}>{errorMessage} </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={6} md='4'>
          <button className={`btn btn-${pertanyaan[displayState].btn1.type} btn-lg`} onClick={() => {
            const data = dataQ
            if (displayState === 'pertanyaan0') {
              data[pertanyaan[displayState].dataType] = 'YES'
            }
            setValData(undefined)
            setDataQ(data)
            setDisplayState(pertanyaan[displayState].btn1.newState)
            setErrorMessage(undefined)
            setComplateQuestion(pertanyaan[displayState].btn1.percentageState)
          }}>{pertanyaan[displayState].btn1.text} </button>
        </Col>
        <Col xs={6} md='4'>
          <button className={`btn btn-${pertanyaan[displayState].btn2.type} btn-lg`} onClick={() => {
            const data = dataQ
            let err = 1
            let selesai = 0
            console.log("test")
            if (displayState === 'pertanyaan5') {
              if (valData) {
                if (data[`Superannuation In`] && data[`Superannuation In`].length > 0) {
                  err = 0
                }
              }

            } if (displayState === 'pertanyaan10') {

              if (valData !== undefined) {
                data[pertanyaan[displayState].dataType] = valData === undefined ? pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 0 : valData
                setErrorMessage(undefined)
                data["Audience"] = audience
                data["Ads Name"] = ad
                props.firebaseApp.database().ref(`qualification/${props.uid}`).set(data).then(() => {
                  selesai = 1
                  setDisplayState('done')
                  setComplateQuestion(100)
                })
              } else {
                setErrorMessage(pertanyaan[displayState].errorMessage)
              }



            } else {
              if ((valData !== undefined || displayState === 'pertanyaan0') || (pertanyaan[displayState].errorMessage === '')) {
                err = 0
              }
            }

            if (selesai === 0) {

              if (err === 0) {

                data[pertanyaan[displayState].dataType] = displayState === 'pertanyaan0' ? 'NO' : valData === undefined ? pertanyaan[displayState].input.devValue ? pertanyaan[displayState].input.devValue : 0 : valData
                setDataQ(data)
                setValData(undefined)
                setDisplayState(pertanyaan[displayState].btn2.newState)
                setErrorMessage(undefined)
                setComplateQuestion(pertanyaan[displayState].btn2.percentageState)

              } else {
                setErrorMessage(pertanyaan[displayState].errorMessage)
              }
            }

          }}>{pertanyaan[displayState].btn2.text} </button>
        </Col>
      </Row>
      {testi}
    </div>
  }

  console.log("dataQ", dataQ)

  return (
    <Layout header={<Header />}>
      <Helmet>
        <style type="text/css">
          {
            ` 

            .noUi-base {
              background-image: linear-gradient(to right, #ff0000, #ff0000 ${slidePercent}%, #eee ${slidePercent}%,#eee 100%) !important; 
            }
            
            `
          }
        </style>
      </Helmet>
      <Row style={{ marginTop: 50, textAlign: 'center' }}>
        <Col xs='12'>
          <p>You are moments away from scheduling a call with one of the country’s leading accredited Financial Planners.</p>
          <h3>Are you interested in receiving up to $100,000* in tax relief and government grants?</h3>
          <p>Complete the questions below to see if you qualify for the FREE 1-hour wealth strategy call.</p>
        </Col>

      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              {display}

            </CardBody>
            <CardFooter>
              <div style={{ width: '100%', background: '#eee' }} >
                <div style={{ width: '100%', backgroundImage: `linear-gradient(      to right,       #007bff,      #007bff ${complateQuestion}%,      #aaa ${complateQuestion}%,          #aaa 100%    )` }}>
                  <p style={{ textAlign: "center", lineHeight: '30px', margin: 0, color: '#fff', fontWeight: 500 }}>You're {complateQuestion}% Complete</p>
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>

    </Layout>
  )
}

export default App;

const Header = () => {

  return (
    <header
      style={{
        background: `#1c2a31`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          textAlign: 'center'
        }}
      >
        <img src={logo} alt="" style={{ margin: '10px  auto' }} />
      </div>
    </header>
  )

}



const pertanyaan: any = {
  pertanyaan0: {
    pertanyaan: [pertanyaanAwal],
    dataType: '05 - Marital Status',
    errorMessage: '',
    btn1: {
      text: 'YES',
      newState: 'pertanyaan1',
      percentageState: 10,
      type: 'info'
    },
    btn2: {
      text: 'NO',
      newState: 'pertanyaan1',
      percentageState: 10,
      type: 'success'
    },
    input: {
      type: 'none'
    }
  },
  pertanyaan1: {
    pertanyaan: ['What is the estimated value of your home?'],
    dataType: '06 - Home Value',
    errorMessage: '',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan0',
      percentageState: 0,
      type: 'info',
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan2',
      percentageState: 20,
      type: 'info'
    },
    input: {
      type: 'slide',
      devValue: 500000,
      range: [0, 2000000]
    },

  },
  pertanyaan2: {
    pertanyaan: ['What is the value of your mortgage?'],
    dataType: '07 - Mortgage Value',
    errorMessage: '',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan1',
      percentageState: 10,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan3',
      percentageState: 30,
      type: 'info'
    },
    input: {
      type: 'slide',
      devValue: 0,
      range: [0, 1600000]
    },
  },
  pertanyaan3: {
    pertanyaan: ['What is your annual income?', 'What is your combined household income?'],
    dataType: '08 - Income Value',
    errorMessage: '',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan2',
      percentageState: 20,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan4',
      percentageState: 40,
      type: 'info'
    },
    input: {
      type: 'slide',
      devValue: 150000,
      range: [0, 360000]
    },
  },
  pertanyaan4: {
    pertanyaan: ['How much savings do you have?', 'What is your combined savings?'],
    dataType: '09 - Saving Value',
    errorMessage: '',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan3',
      percentageState: 30,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan5',
      percentageState: 50,
      type: 'info'
    },
    input: {
      type: 'slide',
      devValue: 50000,
      range: [0, 200000]
    },
  },
  pertanyaan5: {
    pertanyaan: ['What is your  superannuation value?', 'What is your combined superannuation value?'],
    dataType: '10 - Superannuation Value',
    subQuestion: 'Is your super in...',
    centang: ['Normal superfund', 'Self-Managed Super Fund (SMSF)', 'Defined benefit Super', 'Unsure'],
    errorMessage: 'Please enter your superannuation value',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan4',
      percentageState: 40,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan6',
      percentageState: 60,
      type: 'info'
    },
    input: {
      type: 'slide',
      devValue: 0,
      range: [0, 600000]
    },
  },
  pertanyaan6: {
    pertanyaan: ['If we can show you how to get tax relief and government grants through property investment, when are you likely to take action?'],
    dataType: '11 - Take Action',
    errorMessage: 'Please choose an options',
    centang: ['As soon as possible', '1 to 3 Months', '3 to 6 Months', '6 to 9 Months', '9 to 12 Months', 'Unsure'],
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan5',
      percentageState: 50,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan7',
      percentageState: 70,
      type: 'info'
    },
    input: {
      type: 'radio'
    },
  },
  pertanyaan7: {
    pertanyaan: ['Please enter your postcode:'],
    dataType: '04 - Post Code',
    errorMessage: 'Please Enter Postcode',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan6',
      percentageState: 60,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan8',
      percentageState: 80,
      type: 'info'
    },
    input: {
      type: 'text',
      placeholder: 'Enter Postcode',
      width: 3
    }
  },
  pertanyaan8: {
    pertanyaan: [`What's your email address?`],
    dataType: '03 - Email Address',
    errorMessage: 'Please enter your email address',
    sub: 'We respect your privacy and will only use this to send through your results.',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan7',
      percentageState: 70,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan9',
      percentageState: 80,
      type: 'info'
    },
    input: {
      type: 'text',
      placeholder: 'Please enter email address',
      width: 6
    }
  },
  pertanyaan9: {
    pertanyaan: [`What's your mobile number?`],
    dataType: '02 - Phone',
    errorMessage: 'Please enter your phone number',
    centang: [`I understand I'll receive a short 5 minute obligation-free call explaining how to take advantage of these Government tax incentives.`],
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan8',
      percentageState: 80,
      type: 'info'
    },
    btn2: {
      text: 'NEXT',
      newState: 'pertanyaan10',
      percentageState: 100,
      type: 'info'
    },
    input: {
      type: 'text',
      placeholder: '+610000000001',
      width: 4
    }
  },
  pertanyaan10: {
    pertanyaan: ['What is your name?'],
    dataType: '01 - Name',
    errorMessage: ' ',
    // sub: 'Our team will need to speak with you if you wish to take advantage of these tax incentives.',
    btn1: {
      text: 'BACK',
      newState: 'pertanyaan9',
      percentageState: 90,
      type: 'info'
    },
    btn2: {
      text: 'SUBMIT',
      newState: 'done',
      type: 'success'
    },
    input: {
      type: 'text',
      placeholder: 'Enter Your Name',
      width: 6
    }
  }

}
